<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <h3>Validar Pedido - {{ pedidoRequest.idFormatted }}</h3>
                </v-col>
                <v-col 
                    cols="6"
                    style="text-align: right;"
                >
                    <v-chip
                        :color="getStatusColor(pedidoRequest.status)"
                        outlined
                        dark
                    >
                        {{ pedidoRequest.statusDescricao }}
                    </v-chip>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>

            <v-row v-if="pedidoRequest.entregaTurbo == 1">
                <v-col 
                    style="display: flex; flex-direction: column; align-items: center;"
                    cols="12"
                >
                    <div class="entregaTurbo"> ATENÇÃO! Pedido com ENTREGA TURBO</div>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <v-expansion-panels popout>

                        <!-- DADOS DO PEDIDO -->
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-information-outline
                                            </v-icon>
                                            Dados do Pedido
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>

                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="4"
                                            md="6"
                                            sm="12"
                                        >
                                            <label>ID</label>
                                            <v-text-field
                                                v-model="pedidoRequest.idFormatted"
                                                disabled
                                                single-line
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            lg="4"
                                            md="6"
                                            sm="12"
                                        >
                                            <label>Criado Em</label>
                                            <v-text-field
                                                v-model="pedidoRequest.dataCadastroFormatted"
                                                disabled
                                                single-line
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            lg="4"
                                            md="6"
                                            sm="12"
                                        >
                                            <label>Tipo Cliente</label>
                                            <v-text-field
                                                v-model="pedidoRequest.tipoClienteDescricao"
                                                disabled
                                                single-line
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="4"
                                            md="6"
                                            sm="12"
                                        >
                                            <label>Condição de Pagamento</label>
                                            <v-combobox
                                                v-model="pedidoRequest.condicaoPagamentoSelected"
                                                :items="listCondicaoPagamento"
                                                :rules=[validations.required]
                                                item-text="descricao"
                                                item-value="id"
                                                clearable
                                                outlined
                                                disabled
                                            ></v-combobox>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="4"
                                            md="6"
                                            sm="12"
                                        >
                                            <label>Forma de Pagamento</label>
                                            <v-combobox
                                                v-model="pedidoRequest.formaPagamentoSelected"
                                                :items="listFormaPagamento"
                                                :rules=[validations.required]
                                                item-text="descricao"
                                                item-value="id"
                                                clearable
                                                outlined
                                                disabled
                                            ></v-combobox>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="4"
                                            md="6"
                                            sm="12"
                                        >
                                            <label>Marca</label>
                                            <v-text-field
                                                v-model="pedidoRequest.marcaDescricao"
                                                disabled
                                                single-line
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="4"
                                            md="6"
                                            sm="12"
                                        >
                                            <label>Tipo de Frete</label>
                                            <v-combobox
                                                v-model="pedidoRequest.tipoFreteSelected"
                                                :items="listTipoFrete"
                                                :rules=[validations.required]
                                                item-text="descricao"
                                                item-value="id"
                                                clearable
                                                outlined
                                            ></v-combobox>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            lg="4"
                                            md="6"
                                            sm="12"
                                        >
                                            <label>Transportadora</label>
                                            <v-combobox
                                                v-model="pedidoRequest.transportadoraSelected"
                                                :items="listTransportadora"
                                                item-text="idAndNameProcessed"
                                                item-value="id"
                                                :loading="loadingTransportadora"
                                                clearable
                                                outlined
                                            ></v-combobox>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="4"
                                            md="6"
                                            sm="12"
                                        >
                                            <label>Valor Frete</label>
                                            <v-text-field
                                                v-model="pedidoRequest.valorFrete"
                                                prefix="R$"
                                                v-formatMoney="moneyFormat"
                                                single-line
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="4"
                                            md="6"
                                            sm="12"
                                        >
                                            <label>Valor Total</label>
                                            <v-text-field
                                                v-model="pedidoRequest.valorTotalBrutoFormatted"
                                                prefix="R$"
                                                disabled
                                                single-line
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            lg="4"
                                            md="6"
                                            sm="12"
                                        >
                                            <label>Tipo de Pedido</label>
                                            <v-combobox
                                                v-model="pedidoRequest.tipoPedidoSelected"
                                                :items="listTipoPedido"
                                                item-text="descricao"
                                                item-value="id"
                                                clearable
                                                outlined
                                                disabled
                                            ></v-combobox>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>

                        <!-- DADOS DO CLIENTE -->
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-information-outline
                                            </v-icon>
                                            Dados do Cliente
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>

                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="2"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>Código Totvs</label>
                                            <v-text-field
                                                v-model="pedidoRequest.clienteSelected.id"
                                                disabled
                                                single-line
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            lg="2"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>Nro Pedido</label>
                                            <v-text-field
                                                v-model="pedidoRequest.numeroPedidoCliente"
                                                single-line
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            lg="2"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>Cnpj</label>
                                            <v-text-field
                                                v-model="pedidoRequest.clienteSelected.cnpjProcessed"
                                                disabled
                                                single-line
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            lg="3"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>Nome</label>
                                            <v-text-field
                                                v-model="pedidoRequest.clienteSelected.name"
                                                disabled
                                                single-line
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            lg="3"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>Email</label>
                                            <v-text-field
                                                v-model="pedidoRequest.emailCliente"
                                                :rules=[validations.required,validations.email]
                                                single-line
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>

                        <!-- ITENS DO PEDIDO -->
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-information-outline
                                            </v-icon>
                                            Itens do Pedido
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>

                                    <v-row>

                                        <v-col
                                            cols="12"
                                        >

                                            <v-simple-table
                                                fixed-header
                                                style="cursor: pointer;"
                                            >
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                Item
                                                            </th>
                                                            <th class="text-left">
                                                                Codigo
                                                            </th>
                                                            <th class="text-left" style="width: 30%;">
                                                                Descrição
                                                            </th>
                                                            <th class="text-center">
                                                                Prazo Entrega
                                                            </th>
                                                            <th class="text-right">
                                                                PIS
                                                            </th>
                                                            <th class="text-right">
                                                                COFINS
                                                            </th>
                                                            <th class="text-right">
                                                                IPI
                                                            </th>
                                                            <th class="text-right">
                                                                ICP
                                                            </th>
                                                            <th class="text-center">
                                                                Qtde
                                                            </th>
                                                            <th class="text-center">
                                                                Entrega Programada
                                                            </th>
                                                            <th class="text-right" v-if="existeItemCertificacaoEX">
                                                                Valor Cert. EX
                                                            </th>
                                                            <th class="text-right">
                                                                Valor Unitário
                                                            </th>
                                                            <th class="text-right" v-if="isPedidoTurbo">
                                                                Valor Turbo
                                                            </th>
                                                            <th class="text-right">
                                                                Valor Total
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(item, index) in pedidoRequest.listItemsPedido" :key="index"
                                                            style="cursor: pointer;"
                                                            title="Clique para mais detalhes"
                                                            @click="preencherDetalhes(item)"
                                                        >
                                                            <td>{{ item.item }}</td>
                                                            <td>{{ item.codigoProduto }}</td>
                                                            <td>{{ item.descricaoProduto }}</td>
                                                            <td class="text-center">{{ item.prazoEntrega == 0 ? consultarFabricaDescricao : item.prazoEntrega }}</td>
                                                            <td class="text-right">{{ item.aliqPISFormatted }} %</td>
                                                            <td class="text-right">{{ item.aliqCOFINSFormatted }} %</td>
                                                            <td class="text-right">{{ item.aliqIPIFormatted }} %</td>
                                                            <td class="text-right">{{ item.cambioICP }} {{ item.valorICPFormatted }}</td>
                                                            <td class="text-center">{{ item.qtde }}</td>
                                                            <td class="text-center">{{ item.entregaProgramadaDescricao }} {{ item.entregaProgramada == 1 ? ' - ' : '' }} {{ item.dataEntregaFormatted }}</td>
                                                            <td class="text-right" v-if="item.certificacaoEX == 1">{{ item.valorCertificacaoEXFormatted }}</td>
                                                            <td class="text-right">{{ getValorUnitario(item).valorUnitario }}</td>
                                                            <td class="text-right" v-if="isPedidoTurbo">{{ getValorUnitario(item).valorTurbo }}</td>
                                                            <td class="text-right">{{ item.valorTotalBrutoFormatted }}</td>
                                                            <td v-if="item.keyAccount == 1">
                                                                <v-tooltip 
                                                                    left
                                                                >
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon 
                                                                            v-on="on"
                                                                            right 
                                                                            color="green"
                                                                        >
                                                                            mdi-account-key-outline
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Key Account</span>
                                                                </v-tooltip>                                                                
                                                            </td>
                                                        </tr>

                                                        <tr v-if="showDetails && itemDetail != null">
                                                            <td colspan="12" style="margin: 0px; padding: 0px;">
                                                                <table style="width: 80%; border: none; padding: 20px;" cellspacing="10">
                                                                    <tr>
                                                                        <td colspan="2">
                                                                            <label style="color: var(--color__main);">Descontos Aplicados</label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="columnDetail">
                                                                            <label>Desconto Base</label>
                                                                        </td>
                                                                        <td>
                                                                            <label> {{ itemDetail.descontoQtdeBaseFormatted }} % </label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="columnDetail">
                                                                            <label>Desconto de Qtde</label>
                                                                        </td>
                                                                        <td>
                                                                            <label> {{ itemDetail.descontoQtdeFormatted }} % </label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="columnDetail">
                                                                            <label>Desconto Manual</label>
                                                                        </td>
                                                                        <td>
                                                                            <label> {{ itemDetail.descontoUsuarioFormatted }} % </label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="columnDetail">
                                                                            <label>Ouro / Prata</label>
                                                                        </td>
                                                                        <td>
                                                                            <label> {{ itemDetail.descontoCampanhaOuroPrataFormatted }} % </label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="columnDetail">
                                                                            <label>Campanha de Crédito</label>
                                                                        </td>
                                                                        <td>
                                                                            <label> {{ itemDetail.descontoCampanhaCreditoFormatted }} % </label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="columnDetail">
                                                                            <label>Campanha de Estoque</label>
                                                                        </td>
                                                                        <td>
                                                                            <label> {{ itemDetail.descontoCampanhaEstoqueFormatted }} % </label>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                        <tr v-if="showDetails && itemDetail != null">
                                                            <td colspan="12" style="margin: 0px; padding: 0px;">
                                                                <table style="width: 80%; border: none; padding: 20px;" cellspacing="10">
                                                                    <tr>
                                                                        <td>
                                                                            <label style="color: var(--color__main);">Observações Internas</label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            {{ itemDetail.observacao }}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        
                        <!-- MENSAGEM DA NOTA -->
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-information-outline
                                            </v-icon>
                                            Natureza / Mensagem da Nota
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>

                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Natureza</label>
                                            <v-combobox
                                                v-model="pedidoRequest.naturezaSelected"
                                                :items="listNaturezas"
                                                :rules=[validations.required]
                                                item-text="idAndNameProcessed"
                                                item-value="idProcessed"
                                                :loading="loadingNatureza"
                                                clearable
                                                outlined
                                            ></v-combobox>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Mensagem da Nota</label>
                                            <v-textarea
                                                v-model="pedidoRequest.mensagemNota"
                                                single-line
                                                rows="4"
                                                row-height="30"
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        
                        <!-- ANEXOS -->
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-paperclip
                                            </v-icon>
                                            Anexos
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <canvas id="canvas" hidden></canvas>
                                            <label>Ordem de Compra</label>
                                            <v-file-input
                                                v-model="ordemCompraListFiles" 
                                                accept=".*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="addFilesToList(ordemCompraListFiles, ordemCompraFiles)"
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            v-for="(itemDocument, index) in ordemCompraFiles.filter(doc => doc.excluido == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                            style="text-align: center; margin-bottom: 20px;"
                                        >
                                            <v-card
                                                class="justify-center"
                                                @click="downloadFile(itemDocument.id, itemDocument.nomeArquivo)"
                                            >
                                                <v-card-text>
                                                    <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                    <br />
                                                    <span class="imageFileName">{{ itemDocument.nomeArquivo }}</span>
                                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmarExclusaoArquivo(itemDocument)">mdi mdi-delete</v-icon>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Evidência do Preço</label>
                                            <v-file-input
                                                v-model="evidenciaPrecoListFiles" 
                                                accept=".*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="addFilesToList(evidenciaPrecoListFiles, evidenciaPrecoFiles)"
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            v-for="(itemDocument, index) in evidenciaPrecoFiles.filter(doc => doc.excluido == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                            style="text-align: center; margin-bottom: 20px;"
                                        >
                                            <v-card
                                                class="justify-center"
                                                @click="downloadFile(itemDocument.id, itemDocument.nomeArquivo)"
                                            >
                                                <v-card-text>
                                                    <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                    <br />
                                                    <span class="imageFileName">{{ itemDocument.nomeArquivo }}</span>
                                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmarExclusaoArquivo(itemDocument)">mdi mdi-delete</v-icon>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Outros</label>
                                            <v-file-input
                                                v-model="outrosListFiles" 
                                                accept=".*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="addFilesToList(outrosListFiles, outrosFiles)"
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            v-for="(itemDocument, index) in outrosFiles.filter(doc => doc.excluido == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                            style="text-align: center; margin-bottom: 20px;"
                                        >
                                            <v-card
                                                class="justify-center"
                                                @click="downloadFile(itemDocument.id, itemDocument.nomeArquivo)"
                                            >
                                                <v-card-text>
                                                    <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                    <br />
                                                    <span class="imageFileName">{{ itemDocument.nomeArquivo }}</span>
                                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmarExclusaoArquivo(itemDocument)">mdi mdi-delete</v-icon>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        
                        <!-- OBSERVAÇÕES -->
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-information-outline
                                            </v-icon>
                                            Obervações
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>

                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-textarea
                                                v-model="pedidoRequest.observacao"
                                                single-line
                                                rows="4"
                                                row-height="30"
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>

                    </v-expansion-panels>
                </v-col>
            </v-row>
            
            <div
                class="text-center"
            >
                <v-row>
                    <v-col 
                        cols="7"
                        lg="7"
                        md="7"
                        sm="8"
                    >
                        <v-row>
                            <v-col 
                                style="text-align: right"
                                cols="6"
                                lg="8"
                                md="7"
                                sm="6"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            class="mx-2"
                                            v-on="on"
                                            @click="cancel"
                                            style="background-color: var(--color__cinza_escuro) !important; color: #ffffff !important"
                                        >
                                            Cancelar
                                        </v-btn>
                                    </template>
                                    <span>Cancelar</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                style="text-align: center"
                                cols="6"
                                lg="4"
                                md="5"
                                sm="6"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            class="mx-2 buttonCancelarDialog"
                                            v-on="on"
                                            @click="descricaoMotivo = ''; showDialogNegacao = true;"
                                        >
                                            Negar
                                        </v-btn>
                                    </template>
                                    <span>Negar pedido e voltar para o vendedor</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col 
                        cols="5"
                        lg="5"
                        md="5"
                        sm="4"
                    >
                        <v-row>
                            <v-col 
                                style="text-align: left"
                                cols="12"
                                lg="6"
                                md="6"
                                sm="6"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            class="mx-2"
                                            v-on="on"
                                            @click="confirmNegarAprovarPedido('APROVADO', true)"
                                        >
                                            Aprovar
                                        </v-btn>
                                    </template>
                                    <span>Aprovar pedido e colocar na fila para integração com o TOTVS</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>
            </div>
        </v-form>

		<v-dialog
			v-model="showDialogNegacao"
			transition="dialog-top-transition"
			persistent
			width="500"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Informe o motivo da negação
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
					<v-row>

						<v-col cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>Motivo</label>
                            <v-textarea
                                v-model="descricaoMotivo"
                                single-line
                                rows="4"
                                row-height="30"
                                outlined
                            >
                            </v-textarea>
						</v-col>
					</v-row>
					

				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel"
							outlined
							@click="showDialogNegacao = false;"
						>
							Cancelar
						</v-btn>

						<v-btn
							class="mx-2"
							outlined
							@click="confirmNegarAprovarPedido('NEGADO', false)"
						>
							Confirmar
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />
    </v-container>
</template>

<script>
    import { required, validCnpj, validEmail } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { formaterDecimalBR } from '@/utilities/Utils';
    import { 
        TIPO_DOCUMENTO, 
        TIPO_ANEXO, 
        CONSULTAR_FABRICA_DESCRICAO, 
        LIST_TIPO_PEDIDO 
    } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog
        },

        mixins: [Helpers],

        data: () => ({

            validForm: true,

            pedidoRequest: {
                id: 0,
                idFormatted: "#000000",
                cnpjCliente: "",
                nome: "",
                valorFrete: "0,00",
                observacao: "",
                naturezaSelected: null,
                idNatureza: "",
                transportadoraSelected: null,
                idTransportadora: "",
                mensagemNota: "",
                
                clienteSelected: {
                    id: "",
                    name: '',
                    email: ''
                },
                condicaoPagamentoSelected: {
                    id: 0,
                    descricao: ''
                },
                formaPagamentoSelected: {
                    id: 0,
                    descricao: ''
                },
                tipoFreteSelected: {
                    id: 0,
                    descricao: ''
                }
            },

            listCondicaoPagamento: [],
            listFormaPagamento: [],
            listTipoFrete: [],

            loadingNatureza: false,
            listNaturezas: [],

            loadingTransportadora: false,
            listTransportadora: [],

            showDialogNegacao: false,
            descricaoMotivo: "",
            status: 0,
            statusPedido: 0,

            loading: false,

            ordemCompraListFiles: [],
            ordemCompraFiles: [],

            evidenciaPrecoListFiles: [],
            evidenciaPrecoFiles: [],

            outrosListFiles: [],
            outrosFiles: [],

            itemArquivoExclusao: {},

            consultarFabricaDescricao: CONSULTAR_FABRICA_DESCRICAO,

            listTipoPedido: LIST_TIPO_PEDIDO,

            moneyFormat: {
                suffix: '',
                thousands: '.',
                decimal: ',',
                precision: 2,
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },
            
            validations: {
                required: required,
                validCnpj: validCnpj,
                email: validEmail
            },

            tipoAprovadoOuNegado: '',
            showDetails: false,
            itemDetail: null,

            og: Math.pow(10, 2),
        }),

        props: {
            id: {
                default: 0
            }
        },

        computed: {
            existeItemCertificacaoEX() {
                return this.pedidoRequest.listItemsPedido != null &&
                       this.pedidoRequest.listItemsPedido != undefined &&
                       this.pedidoRequest.listItemsPedido.filter(item => item.certificacaoEX == 1).length > 0;
            },

            isPedidoTurbo() {
                return this.pedidoRequest.entregaTurbo == 1
            },
        },

        methods: {

            getValorUnitario(item) {

                let valorUnitario = 0;
                let valorTurbo = 0;

                if (this.isPedidoTurbo == false) {
                    valorUnitario = item.precoBrutoFormatted;
                }
                else {
                    const valorSemTurbo = parseFloat(item.precoBruto) / 1.5;
                    valorUnitario = formaterDecimalBR ( Math.floor( ((valorSemTurbo) * this.og) ) / this.og );

                    const somenteValorTurbo = ( parseFloat(item.precoBruto) - valorSemTurbo ) * item.qtde;
                    valorTurbo = formaterDecimalBR ( Math.floor( ((somenteValorTurbo) * this.og) ) / this.og );
                }

                return {
                    valorUnitario,
                    valorTurbo
                }
            },

            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 1: //ATIVO
                        color = 'var(--color__main)';
                        break;
                        
                    case 0: //INATIVO
                        color = 'var(--color__status_inactive)';
                        break;

                    case 2: //NEGADO
                        color = 'var(--color__red)';
                        break;

                    case 3: //AGUARDANDO INTEGRAÇÃO
                        color = 'var(--color__blue)';
                        break;

                    case 4: //PEDIDO INTEGRADO NO TOTVS
                        color = 'var(--color__main)';
                        break;

                    case 5: //ERRO NA INTEGRAÇÃO COM O TOTVS
                        color = 'var(--color__red)';
                        break;
                
                    default:
                        break;
                }

                return color
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("pedidoModule/GetById", this.id);

                    if (response.success) {
                        this.pedidoRequest = response.result;
                        this.pedidoRequest.valorFrete = this.pedidoRequest.valorFreteFormatted;

                        if (this.pedidoRequest.tipoPedido != null && this.pedidoRequest.tipoPedido != undefined && this.pedidoRequest.tipoPedido != '') {
                            let tipoPedido = this.listTipoPedido.filter(tpd => tpd.id == this.pedidoRequest.tipoPedido)[0];

                            this.pedidoRequest.tipoPedidoSelected = tipoPedido;
                        }
                    }
                }
            },

            addFilesToList(listFiles, files) {

                if (listFiles) {

                    listFiles.forEach(itemFile => {

						if (itemFile) {

                            let nomeArquivo = itemFile.name;

                            if (itemFile.name && itemFile.name.length > 15) {
                                nomeArquivo = itemFile.name.toString().substring(0, 12) + "..."
                            }

                            let item = {
                                id: 0,
                                arquivo: itemFile,
                                nomeArquivo: nomeArquivo,
                                excluido: false,
                            }

                            files.push(item);
						}	
					});
                }
            },

            async uploadFiles(id, chave, arquivo, tipoDocumento, excluido = false) {

                let anexoRequest = {
                    id: id,
                    chave: chave,
                    arquivo: arquivo ? arquivo : new Blob(),
                    nome: arquivo ? arquivo.name : "",
                    tipo: arquivo ? arquivo.type : "",
                    tamanho: arquivo ? arquivo.size : 0,
                    excluido: excluido ? excluido : false,
                    tipoAnexo: TIPO_ANEXO.PEDIDO.value,
                    tipoDocumento: tipoDocumento,
                }

                if (anexoRequest.nome != null && anexoRequest.nome != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("anexoRequest", JSON.stringify(anexoRequest));
                    attachmentFileRequest.append("anexoArquivo", anexoRequest.arquivo, anexoRequest.nome);

                    const result = await this.$store.dispatch("anexoModule/CreateUpdate", attachmentFileRequest);

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async saveFiles() {

                let allResult = [];

                this.ordemCompraFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.id, itemFile.arquivo, TIPO_DOCUMENTO.ORDEM_COMPRA.value, itemFile.excluido);

                    if (result.success == false) {
                        allResult.push({
                            tipoDocumento: TIPO_DOCUMENTO.ORDEM_COMPRA.description,
                            message: result.message
                        })
                    }
                })

                this.evidenciaPrecoFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.id, itemFile.arquivo, TIPO_DOCUMENTO.EVIDENCIA_PRECO.value, itemFile.excluido);

                    if (result.success == false) {
                        allResult.push({
                            tipoDocumento: TIPO_DOCUMENTO.EVIDENCIA_PRECO.description,
                            message: result.message
                        })
                    }
                })

                this.outrosFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.id, itemFile.arquivo, TIPO_DOCUMENTO.OUTROS.value, itemFile.excluido);

                    if (result.success == false) {
                        allResult.push({
                            tipoDocumento: TIPO_DOCUMENTO.OUTROS.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `O [${itemResult.tipoDocumento}] reportou o seguinte erro: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Aviso!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async getLists() {         
                this.listCondicaoPagamento = await this.$store.dispatch("condicaoPagamentoModule/List");
                this.listFormaPagamento = await this.$store.dispatch("formaPagamentoModule/List");
                this.listTipoFrete = await this.$store.dispatch("tipoFreteModule/List");

                this.loadingNatureza = true;
                this.listNaturezas = await this.$store.dispatch("naturezaModule/List");
                this.loadingNatureza = false;

                this.loadingTransportadora = true;
                this.listTransportadora = await this.$store.dispatch("transportadoraModule/List");
                this.loadingTransportadora = false;

                this.consultarAnexos();
            },

            async preencherDetalhes(item) {

                if ( this.itemDetail == null || (this.itemDetail != null && this.itemDetail.id == item.id)) {
                    this.showDetails = !this.showDetails;
                }

                this.itemDetail = item;
            },

            async cancel() {
                this.$router.push({ path: "/pedido/pedidoList" });
            },

            async confirmNegarAprovarPedidoSemTransportadora() {
                this.confirmNegarAprovarPedido (this.tipoAprovadoOuNegado, false);
            },

            async confirmNegarAprovarPedido(tipo, validarTransportadora) {

                if (tipo == "NEGADO") {

                    this.status = 4;
                    this.statusPedido = 2;

                    this.dialog = {
                        show: true,
                        headerText: 'Confirmação',
                        bodyText: 'Você está prestes a NEGAR esse pedido e voltar para o vendedor, confirma sua decisão?',
                        methodConfirm: this.save,
                        params: 0
                    };
                }
                else {

                    await this.$refs.form.validate();

                    if (this.validForm === false) {
                        this.showToast("error", "Aviso!", "Há inconsistências no formulário. Por favor revise!");
                        return false;
                    }
                    else {
                        let idTransportadora = "0";

                        if (this.pedidoRequest.transportadoraSelected != null && this.pedidoRequest.transportadoraSelected != undefined) {
                            idTransportadora = this.pedidoRequest.transportadoraSelected.id;
                        }

                        if (validarTransportadora == true && idTransportadora == "0") {

                            this.tipoAprovadoOuNegado = tipo;

                            this.dialog = {
                                show: true,
                                headerText: 'Confirmação',
                                bodyText: 'Não há nenhuma transportadora definida, deseja continuar assim mesmo?',
                                methodConfirm: this.confirmNegarAprovarPedidoSemTransportadora,
                                params: 0
                            };

                            return false;
                        }
                        else {

                            this.descricaoMotivo = "PEDIDO APROVADO";
                            this.status = 5;
                            this.statusPedido = 3;

                            this.dialog = {
                                show: true,
                                headerText: 'Confirmação',
                                bodyText: 'Você está prestes a APROVAR esse pedido e colocar na fila de integração com o TOTVS, confirma sua decisão?',
                                methodConfirm: this.save,
                                params: 0
                            };
                        }
                    }
                }

            },            

            async confirmarExclusaoArquivo(itemArquivoExclusao) {

                this.itemArquivoExclusao = itemArquivoExclusao;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmação',
                    bodyText: `Confirma a exclusão do arquivo [${itemArquivoExclusao.fileName}]?`,
                    methodConfirm: this.excluirArquivo,
                    params: 0
                };
            },

            async excluirArquivo() {

                this.itemArquivoExclusao.excluido = true;
            },

            async consultarAnexos() {

                this.listAnexos = []
                let listAnexos = await this.$store.dispatch("anexoModule/GetAll", { tipoAnexo: TIPO_ANEXO.PEDIDO.value, chave: this.id });

                let listOrdemCompra = listAnexos.filter(atc => atc.tipoDocumento == TIPO_DOCUMENTO.ORDEM_COMPRA.value);

                if (listOrdemCompra != null && listOrdemCompra != undefined) {
                    
                    listOrdemCompra.forEach(item => {
                        
                        this.ordemCompraFiles.push({
                            id: item.id,
                            arquivo: new File([], item.nome),
                            tipo: 'Ordem de Compra',
                            nomeArquivo: item.nome,
                            excluido: false
                        });
                    });
                }

                let listEvidenciaPreco = listAnexos.filter(atc => atc.tipoDocumento == TIPO_DOCUMENTO.EVIDENCIA_PRECO.value);
                if (listEvidenciaPreco != null && listEvidenciaPreco != undefined) {
                    
                    listEvidenciaPreco.forEach(item => {
                        
                        this.evidenciaPrecoFiles.push({
                            id: item.id,
                            arquivo: new File([], item.nome),
                            tipo: 'Evidência do Preço',
                            nomeArquivo: item.nome,
                            excluido: false
                        });
                    });
                }

                let listOutros = listAnexos.filter(atc => atc.tipoDocumento == TIPO_DOCUMENTO.OUTROS.value);
                if (listOutros != null && listOutros != undefined) {
                    
                    listOutros.forEach(item => {
                        
                        this.outrosFiles.push({
                            id: item.id,
                            arquivo: new File([], item.nome),
                            tipo: 'Outros',
                            nomeArquivo: item.nome,
                            excluido: false
                        });
                    });
                }

                this.showAnexos = true;
            },

            async downloadFile(id, nomeArquivo) {

                if (id != null && id != undefined && id != 0) {

                    this.$spTechApi.get(`anexos/download-file/${id}`, {
                            responseType: "blob",
                        })
                        .then((response) => {

                            var blob = new Blob([response.data], {
                                type: "application/octet-stream",
                            });

                            var url = window.URL.createObjectURL(blob);
                            var a = document.createElement("a");

                            document.body.appendChild(a);
                            a.style = "display: none";
                            a.href = url;
                            a.download = nomeArquivo;
                            a.click();
                            window.URL.revokeObjectURL(url);

                            this.hideLoading();
                        })
                        .catch(error => {
                            
                            let errorMessage = "";
                            if (error.response && error.response.status === 400) {
                                errorMessage = `Erro ao efetuar download do arquivo: [${error.response.data}]`;
                            }
                            else {
                                errorMessage = `Erro ao efetuar download do arquivo: [${error}]`;
                            }
                            
                            this.showToast("error", "Erro!", errorMessage);
                            this.hideLoading();
                        }
                    );
                }
            },

            async save() {

                if (await this.saveFiles()) {
                    this.loading = true;
                    this.showLoading();

                    let orcamentoHistoricoRequest = {
                        id: 0,
                        idOrcamento: this.pedidoRequest.idOrcamentoOrigem,
                        descricao: this.descricaoMotivo
                    }

                    const resultHistorico = await this.$store.dispatch("orcamentoModule/CreateUpdateHistorico", orcamentoHistoricoRequest);

                    if (resultHistorico.success) {

                        this.pedidoRequest.id = this.id;

                        const result = await this.$store.dispatch("pedidoModule/CreateUpdate", this.pedidoRequest);

                        if (result.success === true) {
                            this.showToast("success", "Sucesso!", "Pedido validado com sucesso!");

                            this.$store.dispatch("orcamentoModule/AlterarStatusPelaRevisao", { id: this.pedidoRequest.idOrcamentoOrigem, statusId: this.status });

                            let updateStatusRequest = {
                                id: this.id,
                                status: this.statusPedido,
                                descricao: this.descricaoMotivo
                            }

                            this.$store.dispatch("pedidoModule/AlterarStatus", updateStatusRequest);

                            this.$router.push({ path: "/pedido/pedidoList" });
                        }
                        else {
                            this.showToast("error", "Aviso!", result.message);
                        }
                    }
                    else {
                        this.showToast("error", "Aviso!", resultHistorico.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getLists();

            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }
    
    .deletePhotoIcon {
        float: right;
    }

    .entregaTurbo {
        width: 90%;
        height: 50px;
        background-color: var(--color__red);
        color: #fff;
        text-align: center;
        border-radius: 10px;
        line-height: 50px;
    }

</style>